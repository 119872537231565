<template>
  <modal
    v-model="show"
    class="dashboard-save-modal"
    @close="overwrite = false"
  >
    <loading-spinner v-if="busy" />
    <template v-else>
      <v-errors :errors="getError('base')" />

      <form @submit.prevent="onSubmit">
        <template v-if="overwrite">
          <icon-triangular-warning-sign class="dashboard-save-modal__overwrite-icon" />
          <p class="dashboard-save-modal__overwrite-body">
            {{ $t('portfolios.delete_modal.overwrite_description', { portfolio_name: form.name }) }}
          </p>

          <div class="dashboard-save-modal__actions">
            <button
              class="dashboard-save-modal__confirm"
              type="submit"
            >
              {{ $t('buttons.confirm') }}
            </button>

            <button
              class="dashboard-save-modal__cancel"
              type="button"
              @click.prevent="overwrite = false"
            >
              {{ $t('buttons.cancel') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dashboard-save-modal__heading">
            {{ $t('portfolios.form.title') }}
          </h2>

          <v-field :error="hasError('name')">
            <label for="portfolio_name">{{ $t('portfolios.form.labels.name') }}</label>
            <input
              v-model="form.name"
              v-focus
              :disabled="busy"
              type="text"
            >
            <v-errors
              :errors="getError('name')"
              :prefix="$t('portfolios.form.labels.name') + ' '"
            />
          </v-field>

          <button
            class="dashboard-save-modal__save"
            type="submit"
          >
            {{ $t('buttons.save') }}
          </button>
        </template>
      </form>
    </template>
  </modal>
</template>

<script>
import {
  UNPROCESSABLE_ENTITY
} from '~/helpers/http-status-codes'
import errorsMixin from '~/mixins/mixin-errors'
import IconTriangularWarningSign from '~/icons/IconTriangularWarningSign'
import LoadingSpinner from '~/common/LoadingSpinner'
import Modal from '~/components/modal/Modal'
import VField from '~/components/form/VField'

export default {
  components: {
    IconTriangularWarningSign,
    LoadingSpinner,
    Modal,
    VField,
  },

  mixins: [
    errorsMixin
  ],

  data () {
    return {
      busy: false,
      form: {
        name: undefined
      },
      overwrite: false,
      show: false
    }
  },

  computed: {
    portfolio () {
      return this.$store.state.dashboard.portfolio
    }
  },

  created () {
    if (this.portfolio) {
      this.$set(this.form, 'name', this.portfolio.name)
    }
    this.$root.$on('show-dashboard-save-modal', show => {
      this.show = show
    })

    window.addEventListener('popstate', e => {
      if (e.state.hasOwnProperty('portfolio')) {
        this.$store.dispatch('dashboard/loadPortfolio', { vm: this, id: e.state.portfolio.id })
      }
    })
  },

  methods: {
    close () {
      this.setErrors()
      this.show = false
    },

    onSubmit () {
      this.busy = true

      this.$http({
        url: `/${this.$i18n.locale}/portfolios` + (this.portfolio ? `/${this.portfolio.id}` : ''),
        method: this.portfolio ? 'patch' : 'post',
        data: {
          overwrite: this.overwrite,
          portfolio: {
            ...this.form,
            portfolio_type: 'natural_capital',
            sub_industry_ids: this.$store.state.dashboard.selections.sub_industries,
            production_process_ids: this.$store.state.dashboard.selections.production_processes,
          }
        }
      })
        .then(this.onSuccess)
        .catch(this.onError)
        .finally(() => (this.busy = false))
    },

    onSuccess (response) {
      const portfolio = response.data

      this.$store.dispatch('dashboard/updatePortfolio', portfolio).then(() => {
        const url = new URL(window.location.href)

        url.searchParams.set('portfolio', portfolio.id)

        window.history.pushState({ portfolio }, 'update portfolio', url.toString())

        this.close()
      })
    },

    onError (e) {
      this.overwrite = e.response.data.hasOwnProperty('prompt_overwrite') && e.response.data.prompt_overwrite

      switch (e.response.status) {
      case UNPROCESSABLE_ENTITY:
        this.setErrors(e.response.data.errors)
        this.$emit('errors', e.response.data.errors)
        break
      default:
        this.setErrors({ base: [this.$t('errors.messages.unknown')] })
      }
    }
  }
}
</script>