import VErrors from '~/components/form/VErrors'

export default {
  components: {
    VErrors,
  },
  data () {
    return {
      $_errors: undefined
    }
  },
  methods: {
    firstError (field) {
      return this.hasError(field) ? this.getError(field)[0] : undefined
    },
  
    getError (field) {
      if (this.hasError(field)) {
        return this.$_errors[field]
      }
  
      return []
    },

    setErrors (errors) {
      this.$_errors = errors ? errors : {}
    },
  
    hasError (field) {
      if (field) {
        return this.countErrors(field) > 0
      }
  
      return this.anyErrors()
    },
  
    anyErrors () {
      return this.countErrors() > 0
    },
  
    countErrors (field) {
      if (field) {
        return this.$_errors && this.$_errors.hasOwnProperty(field) ? this.$_errors[field].length : 0
      }
  
      return this.$_errors && Object.values(this.$_errors).reduce((carr, curr) => carr + curr.length, 0)
    },
  }
}