<template>
  <div class="dashboard dashboard--nat-cap">
    <div class="dashboard__header">
      <div class="dashboard__header-content">
        <h1 class="dashboard__title">
          {{ $t('nat_cap_dashboard.title') }}
        </h1>
        <div class="dashboard__fake-tabs">
          <tabs-fake
            class="tabs-fake--basic"
            :children="tabs"
            default-selected-id="dashboard"
            @click:tab="changeTab"
          />
        </div>
      </div>
      <dashboard-filters />
    </div>
    <div class="dashboard__body">
      <div
        v-if="showTabContent('flows')"
        class="dashboard__panel"
      >
        <nat-cap-flows 
          :endpoint="flowsEndpoint"
          :tabs="flowsTabs"
        />
      </div>
      <div
        v-else-if="showTabContent('dashboard')"
        class="dashboard__panel"
      >
        <nat-cap-dashboard
          :endpoint="dashboardEndpoint" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardFilters from './DashboardFilters'
import NatCapDashboard from './NatCapDashboard'
import NatCapFlows from './NatCapFlows'
import TabsFake from '~/components/tabs/TabsFake'

export default {
  name: 'Dashboard',

  components: {
    DashboardFilters,
    NatCapDashboard,
    NatCapFlows,
    TabsFake
  },

  props: {
    dashboardEndpoint: {
      required: true,
      type: String
    },
    flowsEndpoint: {
      required: true,
      type: String
    },
    flowsTabs: {
      required: true,
      type: Array //[{ id: String, title: String }]
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      selectedTab: 'dashboard'
    }
  },

  methods: {
    changeTab (selected) {
      this.selectedTab = selected
    },

    showTabContent (id) {
      return this.selectedTab == id
    }
  }
}
</script>
