<template>
  <modal
    v-model="active"
    class="modal--dashboard"
  >
    <div
      v-if="data"
      class="dashboard-modal"
      :class="`dashboard-modal--${data.dataType}`"
    >
      <div class="dashboard-modal__header">
        <h3 class="dashboard-modal__title">
          {{ data.name }}
        </h3>
        <p
          v-if="data.text"
          class="dashboard-modal__description"
          v-html="data.text"
        />
      </div>
      <div class="dashboard-modal__body">
        <ul class="dashboard-modal__list-items">
          <li
            v-for="item in data.items"
            :key="item.id"
            class="dashboard-modal__list-item"
          >
            <p class="dashboard-modal__text">
              {{ item.name }}
            </p>
            <span
              v-if="data.dataType === 'production_processes'"
              class="dashboard-modal__process-rating"
              :class="ratingClass"
            />
            <a
              v-if="data.dataType === 'eco_servs_or_impact_drivers'"
              :href="getServiceUrl(data.category, item.id)"
              target="_blank"
              class="dashboard-modal__button"
            >
              {{ $t('buttons.explore_map') }}
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="data.href"
        class="dashboard-modal__footer"
      >
        <a
          :href="data.href"
          target="_blank"
          class="dashboard-modal__link"
        >
          {{ $t('buttons.view') }}
          <span class="dashboard-modal__link-icon" />
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import { getRatingClass } from '~/helpers/application-helpers'
import { getMapURLByServiceId, getImpactsMapURl } from '~/helpers/request-helpers'
import Modal from '~/components/modal/Modal.vue'

export default {
  components: {
    Modal
  },

  data () {
    return {
      active: false,
      data: undefined
    }
  },

  computed: {
    ratingClass () {
      return getRatingClass(this.data.selectedRating, this.$i18n.locale)
    },
  },

  created () {
    this.$root.$on('dashboard-modal:open', data => {
      this.data = data
      this.active = true
    })
    this.$root.$on('modal:close', () => (this.active = false))
  },

  methods: {
    getServiceUrl (category, id) {
      switch(category) {
      case 'ecosystem_services':
        return getMapURLByServiceId(id)
      case 'impact_drivers':
        return getImpactsMapURl()
      }
    }
  }
}
</script>
