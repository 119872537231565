

<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.355 18.753"
    :class="data.staticClass"
  >
    <path 
      :fill="props.color"
      d="M21.168 17.189L11.529.496a.987.987 0 00-1.71 0L.132 17.272a.988.988 0 00.855 1.481h19.38a.987.987 0 00.8-1.564zM2.7 16.778l7.977-13.815 7.977 13.816H2.7zm7.465-10.482h1.025a.312.312 0 01.312.321l-.202 6.466a.313.313 0 01-.312.3h-.612a.312.312 0 01-.312-.3l-.206-6.464a.312.312 0 01.312-.321zm1.324 7.948v1.17a.312.312 0 01-.312.312h-1a.312.312 0 01-.312-.312v-1.168a.312.312 0 01.312-.312h1a.312.312 0 01.311.312z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#000',
      type: String,
    },
  },
}
</script>
