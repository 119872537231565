<template>
  <div class="form">
    <div
      v-show="showForm"
      class="form__wrapper"
    >
      <div class="form__content">
        <slot name="content" />
      </div>

      <div class="form__form">
        <slot name="form" />

        <p v-if="error">
          {{ $t('form.error_msg') }}
        </p>
      </div>
    </div>

    <div
      v-if="showThankyou"
      class="form__thankyou flex flex-column flex-center"
    >
      <i class="form__thankyou-icon tick-svg" />
      <h4 class="form__thankyou-title">
        <slot name="thankyou-title">
          {{ $t('buttons.sent') }}
        </slot>
      </h4>
      <div class="form__thankyou-message">
        <slot name="thankyou">
          {{ thankyouMessage }}
        </slot>
      </div>
      <slot name="cta">
        <button
          class="button--green"
          @click="closeThankyou"
        >
          {{ $t('buttons.close') }}
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VForm',

  props: {
    thankyouMessage: {
      type: String,
      default: undefined
    },
    isPopUp: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      showThankyou: false,
      error: false,
    }
  },

  computed: {
    showForm () {
      return !this.isPopUp || !this.showThankyou
    },
  },

  mounted () {
    document.addEventListener('ajax:success', response => {
      this.openThankyou(response)
    })

    document.addEventListener('ajax:error', e => {
      console.error(e)
      this.error = true
    })
  },

  methods: {
    openThankyou () {
      this.showThankyou = true
      this.error = false
    },

    closeThankyou () {
      this.showThankyou = false
      this.error = false

      this.$root.$emit('pop-up:close')
    }
  }
}
</script>
