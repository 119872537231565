<template>
  <div
    class="v-select relative"
    :class="{'disabled': isDisabled}"
  >
    <input
      :id="config.id"
      v-model="selectedInternal.name"
      type="hidden"
      :name="config.id"
    >

    <div
      v-if="config.label"
      class="v-select__label flex flex-v-center hover--pointer"
    >
      <label
        :for="toggleId"
        class="v-select__selection no-margin"
      >{{ config.label }}</label>
      <slot name="label-icon" />
    </div>

    <button
      :id="toggleId"
      class="v-select__toggle h4 flex flex-v-center hover--pointer"
      :class="{'active': isActive}"
      aria-haspopup="true"
      :aria-controls="dropdownId"
      :disabled="isDisabled"
      @click="toggleSelect"
    >
      <span class="v-select__dropdown-text">
        {{ selectionMessage }}
        <span 
          v-show="showSelectedCount"
          class="v-select__dropdown-count"
        >
          {{ selectedInternal.length }}
        </span>
      </span>

      <span class="v-select__drop-arrow select__drop-arrow" />
    </button>

    <div
      v-show="isActive"
      class="v-select__dropdown-wrapper"
    >
      <ul
        :id="dropdownId"
        aria-multiselectable="true"
        role="group"
        class="v-select__dropdown"
      >
        <li
          v-for="option in options"
          :key="option.id"
          :disabled="disabled"
          class="v-select__option"
        >
          <label
            class="v-select__option-label flex flex-v-center no-margin"
            :for="getOptionInputId(option)"
          >
            <input
              :id="getOptionInputId(option)"
              v-model="selectedInternal"
              class="v-select__default-checkbox"
              type="checkbox"
              :disabled="disabled"
              :name="dropdownOptionsName"
              :value="option.id"
            >
            <span class="v-select__checkbox flex-no-shrink" />
            <span>{{ option.name }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixinPopupCloseListeners from '~/mixins/mixin-popup-close-listeners'
import mixinFocusCapture from '~/mixins/mixin-focus-capture'

export default {
  mixins: [
    mixinPopupCloseListeners({closeCallback: 'closeSelect'}),
    mixinFocusCapture({toggleVariable: 'isActive', closeCallback: 'closeSelect', openCallback: 'openSelect'})
  ],

  model: {
    event: 'update:selected-option',
    prop: 'selected'
  },

  props: {
    config: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isActive: false,
      selectedInternal: [],
      dropdownId: 'v-select-dropdown-' + this.config.id,
      dropdownOptionsName: 'v-select-dropdown-input' + this.config.id,
      toggleId: 'v-select-toggle-' + this.config.id,

      mixinModalId: 'v-select-dropdown-' + this.config.id,
      mixinTriggerId: 'v-select-toggle-' + this.config.id,

      buttons_t: this.$t('buttons'),
    }
  },

  computed: {
    isDisabled () {
      return !this.options.length
    },

    selectionMessage () {
      if (this.config.selectionMessage) {
        return this.config.selectionMessage
      }

      const selectedNames = this.options
        .filter(this.isSelected)
        .map(option => option.name)

      return selectedNames.length ? selectedNames.join(', ') : this.buttons_t.select_multi
    },

    showSelectedCount () {
      return this.config.showSelectedCount && this.selectedInternal.length 
    }
  },

  watch: {
    selected (newSelectedOption) {
      this.selectedInternal = newSelectedOption
    },

    selectedInternal (newSelectedInternal) {
      this.$emit('update:selected-option', newSelectedInternal)
    }
  },

  created () {
    this.initializeSelectedInternal()
  },

  methods: {
    closeSelect () {
      this.isActive = false
    },

    openSelect () {
      this.isActive = true
    },

    toggleSelect (e) {
      if (this.options.length && !this.isActive) {
        this.openSelect(e)
      } else {
        this.closeSelect(e)
      }
    },

    initializeSelectedInternal () {
      if (this.selected === null) {
        this.selectedInternal =  []
      } else {
        this.selectedInternal = this.selected
      }
    },

    isSelected (option) {
      return this.selectedInternal.find(selectedOptionID => option.id === selectedOptionID)
    },

    getOptionInputId (option) {
      return `option-${this.config.id}-${option.id}`
    }
  }
}
</script>
