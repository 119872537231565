<template>
  <filters-bar 
    class="filters-bar--dashboard"
    @reset:filters="clearFilters"
  >
    <template slot="filters">
      <div class="dashboard-filters">
        <ul class="dashboard-filters__items">
          <li class="dashboard-filters__item">
            <v-multiselect
              class="dashboard-filters__dropdown v-select--fullscreen-mobile"
              :config="subIndustriesConfig"
              :disabled="busy"
              :options="subIndustries"
              :selected="selectedSubIndustryIds"
              @update:selected-option="onSelectedSubIndustries"
            />
          </li>
          <li class="dashboard-filters__item">
            <v-multiselect
              class="dashboard-filters__dropdown v-select--fullscreen-mobile"
              :config="productionProcessesConfig"
              :disabled="busy"
              :options="productionProcesses"
              :selected="selectedProductionProcessIds"
              @update:selected-option="onSelectedProductionProcesses"
            />
          </li>
        </ul>
      </div>
    </template>

    <template slot="button-right">
      <dashboard-save-button 
        v-show="showSaveButton"
        class="dashboard-save-button--mobile" 
      />
    </template>
  </filters-bar>
</template>

<script>
import { intersection } from 'lodash'
import DashboardSaveButton from './DashboardSaveButton'
import FiltersBar from '~/components/filters-bar/FiltersBar'
import VMultiselect from '~/common/v-select/VMultiselect'

export default {
  components: {
    DashboardSaveButton,
    FiltersBar,
    VMultiselect
  },

  data () {
    return {
      subIndustriesConfig: {
        id: 'sub_industry',
        selectionMessage: this.$t('common.sub_industry'),
        showSelectedCount: true
      },
      productionProcessesConfig: { 
        id: 'production_process', 
        selectionMessage: this.$t('common.production_processes'), 
        showSelectedCount: true 
      },
      subIndustries: [],
      productionProcesses: []
    }
  },

  computed: {
    busy: {
      get () {
        return this.$store.state.dashboard.busy
      },
      set (busy) {
        this.$store.state.dashboard.busy = busy
      }
    },
    selectedSubIndustryIds () {
      return this.$store.state.dashboard.selections.sub_industries
    },

    selectedProductionProcessIds () {
      return this.$store.state.dashboard.selections.production_processes
    },

    showSaveButton () {
      return Boolean(this.selectedSubIndustryIds.length)
    }
  },

  created () {
    // Overwrite state persisted by turbolinks
    this.$store.dispatch('dashboard/resetPortfolio')
    this.initialiseDashboard()
  },

  methods: {
    initialiseDashboard () {
      this.$http.get(`/${this.$i18n.locale}/sub_industries`)
        .then(response => {
          this.subIndustries = response.data
          this.loadPortfolio()
        })
    },

    loadPortfolio () {
      const url = new URL(window.location.href)

      if (url.searchParams.has('portfolio')) {
        const portfolioId = parseInt(url.searchParams.get('portfolio'))

        if (portfolioId) {
          this.$store.dispatch('dashboard/loadPortfolio', { vm: this, id: portfolioId })
        } else {
          console.error('Incorrect portfolio ID', { portfolioId })
        }
      }
    },

    onSelectedProductionProcesses (selectedProductionProcessIds) {
      this.updateSelections({ production_processes: selectedProductionProcessIds })
    },

    onSelectedSubIndustries (selectedSubIndustryIds) {
      this.updateSelectionsAndProductionProcessOptions(selectedSubIndustryIds)
    },

    updateSelectionsAndProductionProcessOptions (selectedSubIndustryIds) {
      if (this.busy) return
      this.busy = true
      this.$http({
        method: 'get',
        url: `/${this.$i18n.locale}/prod_proc_filter`,
        params: {
          'sub_industries': selectedSubIndustryIds
        }
      })
        .then(response => {
          this.productionProcesses = response.data

          this.updateSelections({
            production_processes: this.getIntersectionOfSelectedProductionProcesses(),
            sub_industries: selectedSubIndustryIds
          })
        })
        .finally(() => (this.busy = false))
    },

    /**
     * We use this to maintain the existing selections for Production Processes 
     * when their available options are updated
     */
    getIntersectionOfSelectedProductionProcesses () {
      return intersection(
        this.selectedProductionProcessIds,
        this.productionProcesses.map(productionProcess => productionProcess.id)
      )
    },

    clearFilters () {
      this.updateSelections({
        sub_industries: [],
        production_processes: []
      })
    },

    updateSelections (updatedSelections) {
      this.$store.dispatch('dashboard/updateSelections', updatedSelections)
    }
  }
}
</script>
