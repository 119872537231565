<template>
  <div>
    <tabs-fake 
      :children="tabs"
      class="dashboard__tabs tabs-fake--rounded"
      @click:tab="changeTab"
    />

    <loading-spinner v-if="$store.state.dashboard.busy || busy" />
    <div v-else>
      <am-chart-sankey
        v-show="chartHasData"
        class="dashboard__chart"
        :data="chartData"
      />

      <dashboard-message 
        v-show="!chartHasData"
        class="dashboard-message--no-flows-data"
        :text="t.no_data"
      >
        <template v-slot:icon>
          <icon-sankey />
        </template>
      </dashboard-message>

      <dashboard-message 
        class="dashboard-message--screensize"
        :text="t.screensize"
      >
        <template v-slot:icon>
          <icon-devices />
        </template>
      </dashboard-message>
    </div>
  </div>
</template>

<script>
import AmChartSankey from '~/components/chart/AmChartSankey'
import DashboardMessage from './DashboardMessage'
import IconDevices from '~/icons/IconDevices'
import IconSankey from '~/icons/IconSankey'
import LoadingSpinner from '~/common/LoadingSpinner'
import TabsFake from '~/components/tabs/TabsFake'

export default {
  name: 'NatCapDashboardFlows',

  components: { 
    AmChartSankey,
    DashboardMessage,
    IconDevices,
    IconSankey,
    LoadingSpinner,
    TabsFake 
  },

  props: {
    endpoint: {
      required: true,
      type: String
    },
    tabs: {
      required: true,
      type: Array //[{ id: String, title: String }]
    }
  },

  data () {
    return {
      busy: false,
      chartData: [],
      selectedTabId: this.tabs[0].id
    }
  },

  computed: {
    chartHasData () {
      return this.chartData.length > 0
    },

    selections () {
      return this.$store.state.dashboard.selections
    },

    selectedSubIndustries () {
      return this.selections.sub_industries
    },

    selectedProductionProcesses () {
      return this.selections.production_processes
    },

    t () {
      return this.$t('nat_cap_dashboard.flows')
    }
  },

  watch: {
    selections () {
      this.updateData()
    },
  },

  mounted ()  {
    this.updateData()
  },

  methods: {
    updateData () {
      if (this.selectedSubIndustries.length) {
        this.ajaxRequest()
      } else {
        this.chartData = []
      }
    },

    ajaxRequest () {
      const data = {
        params: {
          prod_processes: this.selectedProductionProcesses,
          sub_industries: this.selectedSubIndustries,
          type: this.selectedTabId
        }
      }
      
      this.busy = true
      this.$http.get(this.endpoint, data)
        .then(response => {
          this.updateSankeyChart(response.data)
        })
        .catch(function (error) {
          console.log('error', error)
        })
        .finally(() => {
          this.busy = false
        })
    },

    changeTab (selected) {
      this.selectedTabId = selected
      this.updateData()
    },

    updateSankeyChart (data) {
      this.chartData = data
    }
  }
}
</script>
