var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sticky-bar',{staticClass:"sm-trigger-sticky"},[_c('nav',{staticClass:"nav flex flex-v-center flex-h-between sm-target-sticky sticky-bar--unstuck",attrs:{"aria-label":"site-nav"}},[_c('a',{staticClass:"h3",attrs:{"href":("/" + (_vm.$i18n.locale))}},[_c('span',{staticClass:"encore-logo nav__logo",attrs:{"title":"ENCORE Home"}}),_vm._v(" "),_c('span',{staticClass:"encore-mobile-logo nav__mobile-logo",attrs:{"title":"ENCORE Home"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M406 179.293V51.085h-60v68.457l-90-89.627L0 284.853v31.232h61v166h159.667v-100h70v100H451v-166h61v-31.232z"}})])])]),_vm._v(" "),_c('span',{staticClass:"nav__left flex-hs-start"},[_vm._t("nav-left")],2),_vm._v(" "),_c('div',{staticClass:"nav__item-container flex",class:_vm.navType,attrs:{"id":_vm.mixinModalId}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBurgerNav),expression:"isBurgerNav"}],staticClass:"button button--plain nav__close icon-close",attrs:{"id":"close-nav-pane"},on:{"click":_vm.closeNavPane}}),_vm._v(" "),_c('ul',{staticClass:"nav__items ul--unstyled flex",attrs:{"role":"menubar"}},[_vm._l((_vm.pages),function(page){return _c('li',{key:page.id,staticClass:"nav__item relative",attrs:{"role":"none"}},[(page.children)?_c('v-nav-dropdown',{attrs:{"item":page,"is-burger":_vm.isBurgerNav}}):_c('v-nav-link',{attrs:{"item":page}})],1)}),_vm._v(" "),(_vm.user)?_c('li',{staticClass:"nav__item relative",attrs:{"role":"none"}},[_c('v-nav-dropdown',{attrs:{"item":{
              id: 'my-profile',
              label: _vm.user.name ? _vm.user.name : _vm.$t('navigation.my_account'),
              url: _vm.$url('#'),
              children: [
                {
                  id: 'account-details-via-popup',
                  label: _vm.$t('navigation.account_details'),
                  url: _vm.$url('#account-management-popup'),
                },
                {
                  id: 'sign-out',
                  label: _vm.$t('navigation.sign_out'),
                  url: _vm.$url('/users/sign_out'),
                  method: 'delete',
                }
              ],
            },"is-burger":_vm.isBurgerNav}})],1):_c('li',{staticClass:"nav__item relative",attrs:{"role":"none"}},[_c('v-nav-link',{attrs:{"item":{
              id: 'authenticate-via-popup',
              label: _vm.$t('navigation.login_signup'),
              url: _vm.$url('#authentication-popup'),
            }}})],1)],2)]),_vm._v(" "),(!_vm.isLarge())?_c('span',{staticClass:"nav__right"},[_vm._t("nav-right")],2):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBurgerNav),expression:"isBurgerNav"}],staticClass:"button button--plain nav__burger icon-burger",attrs:{"id":_vm.mixinTriggerId},on:{"click":_vm.openNavPane}})])])}
var staticRenderFns = []

export { render, staticRenderFns }