import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(TurbolinksAdapter)
Vue.use(Vue2TouchEvents)

import AuthenticationPopup from '~/components/authentication/AuthenticationPopup'
import CardHolder from '~/components/card/CardHolder'
import Carousel from '~/components/carousel/Carousel'
import Dashboard from '~/components/dashboard/Dashboard'
import ExpandableItem from '~/common/ExpandableItem'
import ExploreTool from '~/ExploreTool'
import ExploreWidget from '~/components/explore-widget/ExploreWidget'
import FeedbackForm from '~/components/feedback/FeedbackForm'
import FeedbackPopup from '~/components/feedback/FeedbackPopup'
import FilterPane from '~/components/filters/FilterPane'
import ForgottenPasswordForm from '~/components/authentication/ForgottenPasswordForm'
import I18n from '~/common/translator/I18n'
import IconLock from '~/icons/IconLock'
import IconTick from '~/icons/IconTick'
import LinkTiles from '~/components/link-tiles/LinkTiles'
import ListBox from '~/common/ListBox'
import LocalisedLink from '~/common/translator/LocalisedLink'
import LoginForm from '~/components/authentication/LoginForm'
import MapExplore from '~/components/map/Map'
import MapPage from '~/MapPage'
import NewPasswordForm from '~/components/authentication/NewPasswordForm'
import PopUp from '~/common/PopUp'
import PortfoliosCards from '~/components/portfolios/PortfoliosCards'
import PortfoliosList from '~/components/portfolios/PortfoliosList'
import ProductionProcesses from '~/components/ProductionProcesses'
import ResendConfirmationForm from '~/components/authentication/ResendConfirmationForm'
import ResourcesViewer from '~/components/resources-viewer/ResourcesViewer'
import Store from '~/components/store/Store'
import SuccessFeedback from '~/components/SuccessFeedback'
import Tab from '~/components/tabs/Tab'
import Tabs from '~/components/tabs/Tabs'
import Tooltip from '~/components/tooltip/Tooltip'
import UserAccountManagementPopup from '~/components/authentication/UserAccountManagementPopup'
import UserForm from '~/components/authentication/UserForm'
import VCheckbox from '~/components/form/VCheckbox'
import VForm from '~/components/form/VForm'
import VMultiselect from '~/common/v-select/VMultiselect'
import VNav from '~/common/nav/VNav'
import VPasswordInput from '~/components/form/VPasswordInput'
import VSelectSearchable from '~/components/v-select/VSelectSearchable'

import store from '~/store/store.js'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import axios from 'axios'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached,
 * and it doesn't need to be done manually for every token.
 */

const token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found')
}

Vue.prototype.$http = axios

import { url as localiseUrl } from '~/helpers/localisation-helpers'
Vue.prototype.$url = localiseUrl

import VFocus from '~/vue-plugins/VFocus'
Vue.use(VFocus)

document.addEventListener('turbolinks:load', () => {
  window.app = new Vue({
    el: '#v-app',
    components: {
      AuthenticationPopup,
      CardHolder,
      Carousel,
      Dashboard,
      ExpandableItem,
      ExploreTool,
      ExploreWidget,
      FeedbackForm,
      FeedbackPopup,
      FilterPane,
      ForgottenPasswordForm,
      I18n,
      IconLock,
      IconTick,
      LinkTiles,
      ListBox,
      LocalisedLink,
      LoginForm,
      MapExplore,
      MapPage,
      NewPasswordForm,
      PopUp,
      PortfoliosCards,
      PortfoliosList,
      ProductionProcesses,
      ResendConfirmationForm,
      ResourcesViewer,
      Store,
      SuccessFeedback,
      Tab,
      Tabs,
      Tooltip,
      UserAccountManagementPopup,
      UserForm,
      VCheckbox,
      VForm,
      VMultiselect,
      VNav,
      VPasswordInput,
      VSelectSearchable
    },
    i18n: new VueI18n({ silentTranslationWarn: true }),
    store
  })
})
