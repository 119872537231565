import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
import { storeDashboard } from './_store-dashboard'
import { storeExplore } from './_store-explore'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: undefined,
  },

  modules: {
    dashboard: storeDashboard,
    explore: storeExplore
  },

  mutations: {
    updateUser (state, user) {
      state.user = user
    }
  }
})
