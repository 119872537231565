const BLANK_SELECTIONS = {
  sub_industries: [],
  production_processes: []
}

export const storeDashboard = {
  namespaced: true,

  state: {
    busy: false,
    portfolio: undefined,
    selections: BLANK_SELECTIONS,
  },

  actions: {
    loadPortfolio({ dispatch }, { vm, id }) {
      vm.$http.get(`/${vm.$i18n.locale}/portfolios/${id}`)
        .then(response => dispatch('updatePortfolio', response.data))
    },

    resetPortfolio({ commit }) {
      commit('updatePortfolio', undefined)
      commit('updateSelections', { ...BLANK_SELECTIONS} )
    },

    updatePortfolio({ commit }, portfolio) {
      commit('updatePortfolio', portfolio)
      commit('updateSelections', {
        production_processes: portfolio.production_processes.map(productionProcess => productionProcess.id),
        sub_industries: portfolio.sub_industries.map(subIndustry => subIndustry.id)
      })
    },

    updateSelections({ commit }, updatedSelections) {
      commit('updateSelections', updatedSelections)
    }
  },

  mutations: {
    updatePortfolio(state, portfolio) {
      state.portfolio = portfolio
    },

    /**
     * updateSelections updates both sub-industries and production processes at the same time 
     * avoiding triggering watchers twice when a portfolio is loaded or sub-industry updated.
     */
    updateSelections(state, updatedSelections) {
      state.selections = { ...state.selections, ...updatedSelections }
    }
  }
}