<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    class="icon--info"
    :class="data.staticClass"
  >
    <path d="M10 0a10 10 0 1010 10A10.011 10.011 0 0010 0zm.65 15.933c-.475.079-1.42.277-1.9.317a1.17 1.17 0 01-1.024-.533 1.25 1.25 0 01-.151-1.144l1.89-5.2H7.5A2.349 2.349 0 019.35 7.19a7.236 7.236 0 011.9-.315 1.507 1.507 0 011.024.533 1.25 1.25 0 01.151 1.144l-1.89 5.2H12.5a2.213 2.213 0 01-1.849 2.183zm.6-9.683A1.25 1.25 0 1112.5 5a1.25 1.25 0 01-1.25 1.25z" />
  </svg>
</template>

<script>
export default {
  name: 'IconInfo'
}
</script>
