<template>
  <loading-spinner v-if="$store.state.dashboard.busy || busy" />
  <div
    v-else-if="data"
    class="dashboard-panel"
  >
    <div class="dashboard-panel__header">
      <h3 class="dashboard-panel__title">
        {{ portfolio ? portfolio.name : $t('portfolios.cta_save') }}
        <div
          v-show="isPortfolioDirty"
          class="dashboard-panel__warning-text"
        >
          {{ $t('portfolios.notifications.unsaved_changes') }}
        </div>
      </h3>
      <div class="dashboard-panel__tools">
        <ul class="dashboard-panel__tools-items">
          <li class="dashboard-panel__tools-item">
            <dashboard-save-button class="dashboard-save-button--tablet-up" />
          </li>
        </ul>
      </div>
    </div>
    <div class="dashboard-panel__body">
      <dependencies-impacts :data="data.dependenciesAndImpactsComponent" />
      <dashboard-ratings-list
        category="ecosystem_services"
        :data="data.ecosystemServicesComponent"
      />
      <dashboard-ratings-list
        category="impact_drivers"
        :data="data.impactDriversComponent"
      />
      <main-assets :data="data.assetsComponent" />
    </div>
  
    <dashboard-component-modal />

    <dashboard-save-modal />
  </div>
  <dashboard-message
    v-else
    class="dashboard-message--no-dashboard-data"
    :text="t.no_data"
  >
    <template v-slot:icon>
      <icon-business-plan />
    </template>
  </dashboard-message>
</template>

<script>
import { isEqual } from 'lodash'
import DashboardComponentModal from './DashboardComponentModal'
import DashboardMessage from './DashboardMessage'
import DashboardRatingsList from './DashboardRatingsList'
import DashboardSaveButton from './DashboardSaveButton'
import DashboardSaveModal from './DashboardSaveModal'
import DependenciesImpacts from './DependenciesImpacts'
import IconBusinessPlan from '~/icons/IconBusinessPlan'
import LoadingSpinner from '~/common/LoadingSpinner'
import MainAssets from './MainAssets'

export default {
  components: {
    DashboardComponentModal,
    DashboardMessage,
    DashboardRatingsList,
    DashboardSaveButton,
    DashboardSaveModal,
    DependenciesImpacts,
    IconBusinessPlan,
    LoadingSpinner,
    MainAssets
  },

  props: {
    endpoint: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      busy: false,
      data: null,
      selectedRecord: undefined
    }
  },

  computed: {
    isPortfolioDirty () {
      return this.portfolioSelections && !isEqual(this.portfolioSelections, {
        sub_industries: [...this.selectedSubIndustries].sort(),
        production_processes: [...this.selectedProductionProcesses].sort()
      })
    },

    portfolio () {
      return this.$store.state.dashboard.portfolio
    },

    portfolioSelections () {
      if (this.portfolio) {
        return {
          production_processes: this.portfolio.production_processes.map(o => o.id).sort(),
          sub_industries: this.portfolio.sub_industries.map(o => o.id).sort()
        }
      }

      return undefined
    },

    selections () {
      return this.$store.state.dashboard.selections
    },

    selectedSubIndustries () {
      return this.selections.sub_industries
    },

    selectedProductionProcesses () {
      return this.selections.production_processes
    },

    t () {
      return this.$t('nat_cap_dashboard.data')
    }
  },

  watch: {
    selections () {
      this.updateData()
    },
  },

  created () {
    this.updateData()
  },

  methods: {
    updateData () {
      if (this.selectedSubIndustries.length) {
        this.ajaxRequest(res => {
          this.data = res.data
        })
      } else {
        this.data = null
      }
    },

    ajaxRequest (cb) {
      const data = {
        params: {
          prod_processes: this.selectedProductionProcesses,
          sub_industries: this.selectedSubIndustries
        }
      }

      this.busy = true
      this.$http.get(this.endpoint, data)
        .then(response => { cb(response.data) })
        .catch(error => { console.log('error', error) })
        .finally(() => {
          this.busy = false
        })
    }
  }
}
</script>
