<template>
  <div class="dashboard-pane">
    <div class="dashboard-pane__header">
      <h3 class="dashboard-pane__title">
        {{ title }}
      </h3>
      <div
        v-if="tooltip"
        class="dashboard-pane__tooltip"
      >
        <icon-info class="dashboard-pane__tooltip-icon" />
        <span class="dashboard-pane__tooltip-text">
          {{ tooltip }}
        </span>
      </div>
    </div>
    <div class="dashboard-pane__body">
      <slot />
    </div>
  </div>
</template>

<script>

import IconInfo from '~/icons/IconInfo.vue'

export default {
  name: 'DashboardPanel',

  components: {
    IconInfo
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    }
  }
}
</script>
