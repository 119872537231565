<template functional>
  <div
    v-if="$options.hasErrors(props.errors)"
    class="errors"
    v-bind="data.attrs"
    :class="data.staticClass"
    :style="data.staticStyle"
  >
    <ul
      v-for="(error, index) in props.errors"
      :key="index"
      class="errors__list"
    >
      <li class="errors__error">
        <span class="errors__icon" />
        <span class="errors__description">{{ props.prefix }}{{ error }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      default: () => [],
      type: Array,
      validator: errors => errors.every(error => typeof error === 'string')
    },
    prefix: {
      default: undefined,
      type: String
    }
  },
  hasErrors (errors) {
    return errors && errors.length > 0
  }
}
</script>

