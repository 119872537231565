import { IMPACTS_ID } from '~/helpers/explore-helpers'

export const getMapURLByServiceId = id => id ? `/map?id=${id}` : '/map'

export const getImpactsMapURl = () => `/map?view=${IMPACTS_ID}`

export const getMapURLByView = view => `/map?view=${view}`

export const getAssetPath = id => `/assets/${id}`

export const getEcosystemServicePath = id => `/ecosystem_services/${id}`

export const getImpactDriverPath = id => `/impact_drivers/${id}`